<template>
  <div class="tab-pane active" id="sms-stats">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <carrier-messages-chart
            ref="carrierMessages"
            :start-date="this.startDate"
            :end-date="this.endDate"
            :is-admin="true"
          />
        </div>
        <div class="col-lg block-el">
          <!-- <div v-if="loading" class="qt-block-ui" /> -->
          <div class="loading min-h-300" v-if="loading">
            <LoadingAnim />
          </div>
          <template v-else>
            <div class="row enviadas stats-column-right pr-5">
              <div class="card card-messages card-second-row inner-card">
                <div class="card-body">
                  <Tarifada />
                  <p class="card-text">Tarifadas</p>
                  <span class="card-text">{{ charged }}</span>
                </div>
              </div>
              <div id="infos-top" class="card card-messages">
                <div class="card-body">
                  <LottieAnimMsgGreen />
                  <p class="card-text">Mensagem Enviadas</p>
                  <span class="card-text">{{ outbound ? outbound : 0 }}</span>
                </div>
              </div>
            </div>
            <div class="row recebidas stats-column-right pr-5">
              <div class="card card-messages card-second-row inner-card">
                <div class="card-body">
                  <Fail />
                  <p class="card-text">Falhas</p>
                  <span class="card-text">{{ failures }}</span>
                </div>
              </div>
              <div id="infos-bottom" class="card card-messages">
                <div class="card-body">
                  <LottieAnimMsgPurple />
                  <p class="card-text">Mensagem Recebidas</p>
                  <span class="card-text">{{ inbound ? inbound : 0 }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <status-messages-chart
            ref="statusMessages"
            :start-date="this.startDate"
            :end-date="this.endDate"
            :is-admin="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CarrierMessagesChart from '@/components/rebranding/sms/CarrierMessagesChart.vue';
import StatusMessagesChart from '@/components/rebranding/sms/StatusMessagesChart.vue';
import LottieAnimMsgGreen from '@/components/rebranding/animLottie/MsgGreen.vue';
import LottieAnimMsgPurple from '@/components/rebranding/animLottie/MsgPurple.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import Fail from '@/components/rebranding/animLottie/Fail.vue';
import Tarifada from '@/components/rebranding/animLottie/Tarifada.vue';

export default {
  name: 'SmsStats',
  components: {
    CarrierMessagesChart,
    StatusMessagesChart,
    LottieAnimMsgGreen,
    LottieAnimMsgPurple,
    LoadingAnim,
    Fail,
    Tarifada,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      inbound: 0,
      outbound: 0,
      failures: 0,
      charged: 0,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.fetchDiretionalMessages(this.filters);
      this.fetchTariffedMessages(this.filters);
      this.$refs.carrierMessages.fetch(this.filters);
      this.$refs.statusMessages.fetch(this.filters);
    },
    fetchDiretionalMessages(filter) {
      this.inbound = 0;
      this.outbound = 0;
      this.$store
        .dispatch('fetchAdminSmsMessages', {
          start_date: this.startDate,
          end_date: this.endDate,
          group_by: 'direction',
          filter,
        })
        .then((response) => {
          this.loading = false;
          this.inbound = response.inbound;
          this.outbound = response.outbound;
        });
    },
    fetchTariffedMessages(filter) {
      this.charged = 0;
      this.failures = 0;
      this.$store
        .dispatch('fetchAdminSmsMessages', {
          start_date: this.startDate,
          end_date: this.endDate,
          group_by: 'status',
          filter,
        })
        .then((response) => {
          this.loading = false;
          Object.entries(response).forEach((status) => {
            if (status[0] === 'failed') {
              this.failures = status[1];
            } else {
              this.charged += status[1];
            }
          });
        });
    },
    exportData(format) {
      this.$refs.carrierMessages.exportData(format);
      this.$refs.statusMessages.exportData(format);
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-column-right {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-end;
}
.card-messages {
  /* margin-left: auto; */
  max-height: 230px;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: var(--clr-light-2);
      font-weight: bold;
    }
  }
}
#infos-bottom,
#infos-top {
  position: relative;
  z-index: 1;
  min-width: 260px;
}
.card-messages.inner-card {
  /* position: absolute;
  right: 0; */
  /* opacity: 0; */
  z-index: 1;
}
.card-messages.inner-card.active {
  /* position: absolute;
  right: 325px; */
  z-index: 0;
  /* opacity: 1; */
}
/* .card-messages.inner-card.active:last-of-type {
  right: 470px;
} */

.darkmode .card {
  background-color: var(--background-3);
}
</style>
