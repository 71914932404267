<template>
  <div>
    <chart
      ref="chart"
      :title="$t('sms.reports.carriers')"
      :data="data"
      :loading="loading"
    ></chart>
  </div>
</template>

<script>
import moment from 'moment';
import Chart from '@/components/rebranding/Chart.vue';

export default {
  name: 'CarrierMessagesChart',
  components: {
    Chart,
  },
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    isAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: true,
      data: [],
      uri: '',
    };
  },
  computed: {
    timeWindow() {
      if (moment(this.endDate).diff(moment(this.startDate), 'days')) {
        return 'day';
      }
      return 'hour';
    },
  },
  methods: {
    checkAdmin() {
      if (this.isAdmin) {
        this.uri = 'fetchAdminSmsMessages';
      } else {
        this.uri = 'fetchSmsInsights';
      }
    },
    fetch(filter) {
      this.checkAdmin();
      this.loading = true;
      this.$store
        .dispatch(this.uri, {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'carrier',
          filter,
        })
        .then((response) => {
          this.data = response;
          // this.series_line = Object.values(series);
          this.loading = false;
        });
    },
    exportData(format) {
      this.$refs.chart.exportData(format);
    },
  },
};
</script>

<style scoped lang="scss">
.chart-opt::v-deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.chart-opt span:first-of-type {
  margin-right: auto;
  font-weight: bold;
}
.icon_opt {
  background-color: #f7f7f7;
  padding: 4px;
  border-radius: 5px;
  font-size: 32px;
  max-width: 42px;
  cursor: pointer;
  color: var(--gray-font-color);
  transition: 0.5s;
}
.icon_opt.active {
  color: var(--clr-yup-purple);
}
.icon_opt:hover {
  background-color: #e7e7e7;
}
.darkmode .icon_opt {
  background-color: var(--background-3);
}
</style>
